const buttons = {
  'Recovery button': 'Восстановить',
  'Change button': 'Изменить',
  'Back button': 'Назад',
  'Create button': 'Создать',
  'Save button': 'Сохранить',
  'Update button': 'Обновить',
  'Delete button': 'Удалить',
  'Search button': 'Найти',
  'Clear button': 'Очистить',
  'Copy button': 'Скопировать',
  'Cancel button': 'Отмена',
  'Ok button': 'Ok',
  'Back to Home': 'Вернуться на главную',
  'status-city': 'Инфо',
  'cancel-city': 'Отмена',
  'register-city': 'Зарегистрировать',
  'Create transaction': 'Создать транзакцию',
  'Create transaction confirmation': 'Создать справку о транзакциях',
  'Create': 'Создать'
};

const menu = {
  'Transaction menu': 'Транзакции',
  'Users menu': 'Пользователи',
  'Users inactive menu': 'Не активные',
  'Users active menu': 'Активные',
  'Role menu': 'Роли',
  'User Item': 'Информация о пользователе',
  'Banks menu': 'Банки',
  'Flow menu': 'Схемы',
  'Gateway menu': 'Шлюз',
  'Cascading menu': 'Каскад',
  'Cascading rules menu': 'Правила',
  'Cascading models menu': 'Модели',
  'Terminals menu': 'Терминалы',
  'Merchant menu': 'Мерчанты',
  'Description menu': 'Экспорт документов',
  'Description menu custom': 'Экспорт',
  'Codes menu': 'Коды',
  'Bin menu': 'Bin',
  'Reconciliation menu': 'Сверка',
  'Catalogs menu': 'Справочники',
  'Sanctions List':'Санкционные списки',
};

const text = {
  'Having an account': 'Уже есть аккаунт',
  'Reset account password': 'Восстановить доступ',
  'Success update': 'Запись обновлена',
  'Do you want to remove': 'Хотите удалить запись?',
  'Register account': 'Регистрация',
  'Success registration': 'Вы успешно зарегистрированы',
  'Success deleted': 'Запись удалена',
  'Reset title': 'Восстановление пароля',
  'Success recovery send token': 'Инструкция по была отправлена на email',

  'Transactions List': 'Список транзакций',
  'Transactions Confirmation': 'Справка транзакций',
  'Transactions Item': 'Информация о транзакции',

  'Users List': 'Список пользователей',
  'User Item Update': 'Обновление пользователя',
  'User Item Create': 'Создание пользователя',

  'Roles List': 'Список ролей',
  'Role Item Create': 'Создание роли',
  'Role Item Update': 'Обновление роли',

  'Terminals List': 'Список терминалов',
  'Terminals Item Id': 'Информация о терминале',
  'Terminals Model Create': 'Создание нового терминала',
  'Terminals Token Update': 'Обновление ключей',
  'Terminals Item Update': 'Обновление терминала',
  'Terminal Item Id': 'Информация о терминале',
  'Do you want to update token': 'Вы действительно хотите обновить ключ?',

  'Merchant List': 'Список мерчантов',
  'Merchant Item Id': 'Описание мерчанта',
  'Merchant Item Update': 'Обновление мерчанта',
  'Merchant Item Create': 'Создание мерчанта',
  'Merchant Token Update': 'Обновление ключа мерчанта',

  'Banks List': 'Список банков',
  'Banks Item Id': 'Информация о банке',
  'Bank Item Create': 'Добавление нового банка',
  'Bank Item Update': 'Обновление банка',
  'Bank Deposit Update': 'Обновление лимита',

  'Transactions Flow List': 'Список схем транзакций',
  'Transactions Flow Create': 'Создание схемы транзакции',

  'Gateway List': 'Список шлюзов',
  'Gateway Item Create': 'Создание шлюза',
  'Gateway Item': 'Описание шлюза',
  'Gateway Item Update': 'Обновление шлюза',

  'Cascading Rules List': 'Список правил',
  'Cascading Models List': 'Список моделей',
  'Cascading Model Create': 'Создание модели',

  'Codes List': 'Список кодов',
  'Codes Item Id': 'Информация о коде',
  'Code Item Update': 'Обновления кода',
  'Code Item Create': 'Создание кода',
  'Transactions Logs Item': 'Логи транзакций',
  'Reconciliation List': 'Сверка',
  miss_in_bnk: 'Отсутствует в банке',
  miss_in_pt: 'Отсутствует в paytech',
  trn_not_registered: 'Не зарегистрировано',
  miss_in_bnk2: 'Внимание!!!',
  dateStart: 'Период с',
  dateEnd: 'Период по',
  tranTypeId: 'Тип операции',
  bankId: 'Банк',
  respCode: 'Статус',
  amountFrom: 'Сумма от',
  amountTo: 'Сумма до',
  'card first 6 number': 'Первые 6 цифр карты',
  'card last 4 number': 'Последние 4 цифры карты',
  tranId: 'ID транзакции',
  orderId: 'ID кредита',
  clientId: 'ID клиента',
  gateway: 'Банк',
  acsStatus: 'Статус 3DS',
  amount: 'Сумма',
  approval: 'Код авторизации',
  callbackUrl: 'Call back URL',
  fee: 'Комиссия',
  getewayRefNo: 'ID банка',
  lang: 'Язык',
  pan: 'Карта',
  respCodeId: 'Код ответа',
  rrn: 'RRN',
  tranType: 'Тип операции',
  description: 'Описание',
  status: 'Статус',
  timezone: 'Временная зона',
  comment: 'Коментарии',
  bankName: 'Банк',
  fields: 'Другие поля',
  langEn: 'Английский',
  langRu: 'Русский',
  langUk: 'Украинский',
  external: 'Код',
  merchId: 'Мерчант',
  Profile: 'Профиль',
  Settings: 'Настройки',
  Logout: 'Выйти',
  Balance: 'Баланс',
  'Hold Funds': 'В обработке',
  'Available Funds': 'Доступно для выдачи',
  'Day Transactions Credit': 'Сумма выдачи за сегодня',
  'Day Transactions Debit': 'Сумма погашений за сегодня',
  'Month Transactions Credit': 'Сумма выдачи за месяц',
  'Month Transactions Debit': 'Сумма погашений за месяц',

  'Export List': 'Экспорт файлов',
  generated_reports_list: 'Список доступных файлов',
  'City24 Files List': 'Экспорт файлов city24',
  'Download File': 'Скачать файл',
  'Bin List': 'Bin',
  'Invitation link': 'Ссылка для приглашения пользователей',

  'Success tab': 'Успешные',
  'Failed tab': 'Отклоненные',
  'Conversion tab': 'Конверсия',

  'Password was successfully changed': 'Пароль был успешно изменен',
  'Reset success send':
    'Инструкция по восстановлению пароля была отправлена на указанный email',
  'Request success send': 'Запрос успешно отправлен',
  'Error!': 'Ошибка!',
  'An error occurred during creating transaction. Please try again later.': 'При создании ссылки произашла ошибка. Попробуйте позже.',
  'Transaction frame ready': 'Ссылка для оплаты создана',
  'Link copied': 'Скопировано',
  status_new: 'Новый',
  status_processing: 'В обработке',
  status_finished: 'Готов',
  status_failed: 'Ошибка',
  'Status done': 'Обработано',
  'Status pending': 'В обработке',
  'Status failed': 'Ошибка',
  'Status loaded': 'Загружено',
  'result': 'Результат',
  'Result show': 'Результат',
  'No result': 'Не готово',
  'createdBy': 'Автор',
  'Bank statements': 'Выписки',
  'Bank Operations': 'Операции',
  'type': 'Тип',
  'bankRef': 'Реф',
  'Debit operation': 'Выплата',
  'Credit operation': 'Пополнение',
  'Merchant Overdraft History': 'История овердрафта',
  'transaction': 'Транзакция',
  'Overdrafts': 'Овердрафты',
  'Overdraft': 'Овердрафт',
  'Start date': 'Дата начала',
  'Card Issuer Bank Name': 'Название банка эммитнта',
  'Terminal fee rules': 'Правила подсчета комиссии банка',
  'Create rule': 'Новое правило',
  'Edit rule': 'Редактирование',
  'Leave empty to create default rule': 'Если оставить дату пустой, то будет созданно правило по умолчанию',
  'Leave empty to create wildcard rule': 'Если оставить название банка пустым, то будет созданно правило по умолчанию',
  'Default rule': 'Нет ограничений',
  'No date restrictions': 'Нет ограничений по дате',
  'Fee Rules': 'Правила подсчета комиссии',
  'NoFeeRulesDefined': 'Для данного терминала нет правил подсчета комиссии. Создайте первое правило',
  'Successfully updated': 'Изменено успешно',
  'Successfully created': 'Создано успешно',
  'Hide deleted': 'Скрыть удаленные',
  'Show deleted': 'Показать удаленные',
  'Deleted': 'Удаленно',
  'Sanctions White List': 'Белый список санкций',
  'Create sanctions white list entities': 'Добавить исключение',
};

const fields = {
  orderBy: 'Сортировать по',
  sortDescending: 'По убыванию',
  sortAscending: 'По возрастанию',
  uuid: 'ID',
  name: 'Название',
  createOn: 'Создано',
  merchant: 'Мерчант',
  tranId: 'Id транзакции',
  tranType: 'Тип операции',
  pan: 'Номер карты',
  amount: 'Сумма',
  fee: 'Комиссия',
  gateway: 'Экваир',
  respCode: 'Код ответа',
  lang: 'Язык',
  editOn: 'Обновлено',
  firstName: 'Имя',
  lastName: 'Фамилия',
  phone: 'Телефон',
  loginTries: 'Попыток авторизации',
  lastLogin: 'Дата авторизации',
  linkToken: 'Ключ',
  role: 'Роль',
  'email table': 'Email',
  field_email: 'Email',
  'role name field': 'Роль',
  'permissions field': 'Разрешения',
  'name bank field': 'Название банка',
  depositLimit: 'Лимит депозита',
  keyToken: 'Ключи',
  flowName: 'Название схемы',
  endpoint: 'Точка входа',
  env: 'Окружение',
  bank: 'Банк',
  'name gateway field': 'Название шлюза',
  'Select value': 'Выбрать значение',
  type_operation: 'Тип операции',
  notificationChannel: 'Канал нотификации',
  'Set deposit limit': 'Установить',
  'Increase deposit limit': 'Увеличить',
  'Decrease deposit limit': 'Уменьшить',
  'Comment show': 'Комментарий',
  Loading: 'Загрузка',
  'Upload file': 'Загрузить файл',
  cityRespCode: 'City24 статус',
  company_email: 'Email компании',
  company_id: 'ID компании',
  'TranId': 'ID транзакции',
  'Description': 'Описание',
  'Agreement number': 'Номер договора',
  'Fee': 'Комиссия',
  'Amount': 'Сумма',
  'Payment link': 'Ссылка для оплаты',
  'docRef': 'Номер документ',
  'document reference': 'Номер документ',
  'source': 'Источник',
  'extra': 'Дополнительно',
  'first name': 'Имя',
  'last name': 'Фамилия',
  'notes': 'Заметки',
  'Excluded': 'Исключенно',
  'Critical': 'Обязательно',
  frequency: 'Период',
  startedAt: 'Начало',
  finishedAt: 'Конец',
  status: 'Статус',
  'Server maintenance schedule': 'Расписание серверных работ',
  'Create schedule': 'Создать расписание',
  'Edit schedule': 'Редактировать расписание',
  gatewayRefNo: 'Ref банка',
  holder: 'Владелец карты',
  customerId: 'Id клиента',
  ip: 'IP адрес',
  cardHash: 'Хеш карты',
  country: 'Страна',
  city: 'Город',
  email: 'Email',
  panHash: 'Хеш карты',
  clientIp: 'ip клиента',
  customerEmail: 'Email клиента',
  cardHolder: 'Card holder',
  respMessage: 'Описание статуса',
  timezone: 'Часовой пояс',
  region: 'Регион',
  country_code: 'Код страны',
  country_code3: 'Код страны',
  knownHolders: 'Держатели карты',
  documentRef: 'Номер документа'
};

const server = {
  'Token error': 'Неверный ключ',
  '404 error title': 'Страница не найдена',
  '404 error description':
    'Страница не найдена. Скорее всего она была удалено или перемещена :('
};

const validationForm = {
  required: 'Поле обязательно для заполнения',
  email: 'Поле должно быть почтовым ящиком',
  'Passwords must match': 'Пароли не совпадают',
  'Error password format':
    'В пароле должно быть минимум 8 символом. Используйте прописные и заглавные буквы,а также цифры и спец. символы',
  'Error phone format': 'Телефон должен быть в формате 380XXXXXXXXX'
};

export const ru = {
  'Forgot password': 'Забыли пароль?',
  'Create new account': 'Создать аккаунт',
  'Login text': 'Авторизация',
  ...buttons,
  ...fields,
  ...text,
  ...validationForm,
  ...menu,
  ...server
};
